import { graphql } from "gatsby";
import * as React from "react";
import Img from "gatsby-image";
import linkedinLogo from "../images/linkedin.svg";
import logo from "../images/monkey_lgogo_glitch_animation.gif";

export default ({ data }) => {
  return (
    <main className="container mx-auto text-white">
      <title>MonkeyBusters</title>
      {/* <Img fixed={data.file.childImageSharp.fixed} /> */}
      <img src={logo} className="mx-auto object-scale-down" style={{ height: "80vh" }} />
      <div className="fixed bottom-4 right-4 flex space-x-2">
        <a
          target="_blank"
          className="hover:underline"
          href="https://www.linkedin.com/company/monkeybustersholding/about/?viewAsMember=true"
        >
          <img src={linkedinLogo} className="hover:opacity-80 h-16 -my-5" />
        </a>
        <a
          className="hover:underline text-gray-300"
          href="/impressum"
        >
          Legal stuff
        </a>
      </div>
    </main>
  );
};

// export const query = graphql`
//   query {
//     file(relativePath: { eq: "monkey_lgogo_glitch_animation.gif" }) {
//       childImageSharp {
//         fixed(width: 125, height: 125) {
//           ...GatsbyImageSharpFixed
//         }
//       }
//     }
//   }
// `
